import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import PredioApple from "../../images/predioJacques.png";
import picarrasAerea from "../../images/picarrasAerea.jpeg";
import Progress from "../../components/progress";

export default function JacquesBeach() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Jacques Beach"
      />
      <h2 className="my-16 italic text-center">
        A exclusividade que você espera está neste empreendimento Bivver!
      </h2>
      <h3 className="text-center">
        Jacques Beach um sobrado surpreendente, com padrão de acabamento e
        sofisticação sem igual, localizado num dos principais points de surf do
        litoral sul brasileiro em Balneário Piçarras.
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-12 self-center">
        <img className="lg:w-3/5 lg:ml-32" src={PredioApple} />
        <p className="text-md">
          É o imóvel dos seus sonhos em Balneário Piçarras! Sobrado de alto
          padrão com linhas arquitetônicas modernas e a beira-mar!
          <br />
          <br />
          Foi criado para dar a sua família o melhor que a praia pode oferecer!
          Tem vista para mar em todos os quartos, acabamento em alto padrão,
          quatros suítes, cozinha em conceito americano, área gourmet, piscina
          com deck, escada com iluminação e muito mais!
          <br />
          <br />
          Tudo foi pensado para você e sua família, cada detalhe deste
          empreendimento reflete o estilo Bivver Exclusivity: luxo,
          exclusividade e bem-estar!
        </p>
      </div>
      <h2 className="text-center mb-8">Características</h2>
      <div className="bivverBlack mb-10 grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-48 self-center">
        <div>
          <div>
            <b>Edificações</b>
          </div>
          <div className="my-2">4 Suítes</div>
          <div className="my-2">Sala de jantar</div>
          <div className="my-2">Sala de estar</div>
          <div className="my-2">Cozinha</div>
          <div className="my-2">Cozinha com conceito americano</div>
          <div className="my-2">Área de serviço</div>
          <div className="my-2">Banheiro social</div>
          <div className="my-2">2 Lavabos</div>
          <div className="my-2">1 Escada aparente</div>
        </div>
        <div>
          <div>
            <b>Diferenciais</b>
          </div>
          <div className="my-2">Frente para o mar</div>
          <div className="my-2">Fachada em concreto aparente</div>
          <div className="my-2">Quiosque com churrasqueira</div>
          <div className="my-2">Piscina com deck vista mar</div>
          <div className="my-2">Área gourmet</div>
          <div className="my-2">Escada com iluminação e nicho</div>
          <div className="my-2">3 Vagas de estacionamento cobertas</div>
        </div>
      </div>
      <center>
        <h2>Acompanhamento da obra</h2>
      </center>
      <center>
        <h5>Data de inicio: 10/2019 | Data de término : xx/xxxx</h5>
      </center>
      <br />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Terraplanagem</h4>
        <Progress percentage="100" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Fundação</h4>
        <Progress percentage="100" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Estrutura</h4>
        <Progress percentage="76" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Alvenaria</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Instalações</h4>
        <Progress percentage="39" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Pinturas</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Reboco Interno</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Reboco Externo</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Ceramica</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Esquadrias</h4>
        <Progress percentage="0" />
      </div>
      <div>
        <h2 className="font-bold text-center my-10">Localização</h2>
        <iframe
          className="lg:w-1/2 w-full lg:mx-64 border-solid border-2 border-blue-500"
          title="SoftinLocationMap"
          height="360"
          frameBorder="0"
          src="https://maps.google.com/maps?q=R.%20Albatroz%2C%2066%20pi%C3%A7arras&t=&z=17&ie=UTF8&iwloc=&output=embed"
          allowFullScreen=""
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-24 self-center">
        <div>
          <h2 className="mb-8">Por que morar em Piçarras?</h2>
          <p className="text-sm">
            Com uma localização geográfica privilegiada, ficando aproximadamento
            20 quilômetros do aeroporto de Navegantes e ligada por vias
            pavimentadas a BR-101, o que garante acesso rápido a outros polos de
            diversão e passeio, como o parque temático de Beto Carrero World, em
            Penha, a famosa Oktoberfest em Blumenau e ótimas compras em Brusque.
            <br />
            <br />O Balneário Piçarras abrange belas praias que possuem bandeira
            azul (certificado mundial de balneabilidade), tranquilidade e
            ótimcas opções de entretenimento em família. Com uma excelente
            infraestrutura, a cidade está preparada para recepcionar da melhor
            forma possível turistas de todos os cantos do mundo. Pousadas,
            bares, casas noturnas, mercados e restaurantes que oferecem o famoso
            rodízio de frutos do mar, são apenas uma pequena amostra do que
            Piçarras tem a oferecer.
          </p>
        </div>
        <img src={picarrasAerea} />
      </div>
    </Layout>
  );
}
